import React, { Component } from "react";
// import axios from "axios";
import {RouteComponentProps, useHistory} from 'react-router-dom';
import AuthService from "./../services/AuthService";
import {} from 'react-router-dom';
import "./app.css";
import { apiDomainForComponents, secondAdminEmail, contactEmail } from "./config";



class Login extends Component {
    state = {username: '', password: ''}
    async handleFormSubmit(event) {
        event.preventDefault();
        const postData = {username: this.state.username, password: this.state.password};
        console.log(postData.username);
        localStorage.setItem('user-email', postData.username);
        const response = await AuthService.doUserLogin(postData);
        if (response) {
            AuthService.handleLoginSuccess(response);
            //this.props.history.push('/lista-aukcji');
            window.location.replace("/lista-aukcji");
        }
    }
    render() {
        const {username,password} = this.state;
        return  <div className="ui middle aligned center aligned grid">
            <div className="column">
                <h2 className="ui image header">
                    <div className="content">
                        <h1>Art-Cars.pl</h1>
                        {/* <img src={'https://moto-cars.pl/logo.png'} style={{margin:'10px auto'}} alt={'Moto-cars Logo'}/> */}
                    </div>
                </h2>
                <form onSubmit={event => this.handleFormSubmit(event)} className="ui large form">
                    <div className="ui stacked secondary  segment">
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="user icon"></i>
                                <input type="text" name="email" placeholder="E-mail" value={username} onChange={event => this.setState({username: event.target.value})}/>
                            </div>
                        </div>
                        <div className="field">
                            <div className="ui left icon input">
                                <i className="lock icon"></i>
                                <input type="password" name="password" placeholder="Hasło" value={password} onChange={event => this.setState({password: event.target.value})}/>
                            </div>
                        </div>
                        <button className="ui fluid large teal submit button" style={{backgroundColor: 'red'}} >Zaloguj</button>
                        <p style={{marginTop:'20px'}}>Aby się zarejestrować napisz na: <a href={'mailto:'+contactEmail}>{contactEmail}</a></p>
                    </div>



                </form>
                <div className="ui message"><a href={'/przypomnij-haslo'}>Przypomnij hasło</a></div>
            </div>
        </div>
    }
}

export default Login;